.j-disease-dash-covid-pdl h3 {
    background-color: #f39c12;
}

.j-disease-dash-covid-pdl > div:nth-of-type(1) {
    background-color: rgba(243, 156, 18, 0.7);
}

.j-disease-dash-covid-pdl > div:nth-of-type(1) table td {
    border: 1px solid #f39c12;
}

.j-disease-dash-covid-pdl > div:nth-of-type(1) tbody tr:hover {
    background-color: #f39c12;
}

.j-vaccinees-disclaimer {
    color: #1F4E78;
    padding-top: 5px;
    font-size: 14px;
}