.j-config-list-header {
    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    overflow: auto;
    padding: 0 10px 5px 10px;
    border-bottom: 1px solid #d2d6de;
    background-color: #fff;
    font-weight: bold;
    z-index: 2;
}

.j-config-list-header span { display: block; float: left; }
.j-config-list-item {
    overflow: auto;
    padding: 10px;
    border-bottom: 1px solid #d2d6de;
}
.j-config-list-item > div {
    display: flex;
    align-items: center;
    float: left;
}
.j-config-list-item:hover { background-color: #f5f5f5; }

.j-config-list-header-single span { width: 100%; }
.j-config-list-item-single > div:nth-child(1) { width: 85%; }
.j-config-list-item-single > div:nth-child(2) { width: 15%; justify-content: flex-end; }

.j-config-item-tag {
    display: inline-block;
    padding: 1px 8px;
    vertical-align: middle;
    border-radius: 12px;
    font-size: 13px;
    background-color: #00c0ef;
    font-weight: bold;
    color: #fff;
}
.j-config-item-tag:not(:first-child) {
    margin-left: 5px;
}

.j-config-item-tag-active { background-color: #00a65a; }
.j-config-item-tag-in-use { background-color: #00a65a; }
.j-config-item-tag-inactive { background-color: #d73925; }
.j-config-item-tag-system { background-color: #ca195a; }

.j-indicator-categories-header {}
.j-indicator-categories-header span:nth-child(1) { width: 8%; justify-content: center; text-align: center }
.j-indicator-categories-header span:nth-child(2) { width: 77%; }
.j-indicator-categories-header span:nth-child(3) { width: 15%; }
.j-indicator-categories-item div:nth-child(1) { width: 8%; justify-content: center; font-weight: bold; }
.j-indicator-categories-item div:nth-child(2) { width: 77%; }
.j-indicator-categories-item div:nth-child(3) { width: 15%; justify-content: flex-end; }

.j-indicator-subcategories-select {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.j-indicator-subcategories-select > div:nth-child(1) { width: calc(100% - 220px); }
.j-indicator-subcategories-select > div:nth-child(2) { width: 200px; padding-bottom: 15px; }
.j-indicator-subcategories ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    width: 100%;
}
.j-indicator-subcategories ul ul {
    padding-left: 36px;
}
.j-indicator-subcategories-item {
    padding: 2px 10px;
    display: flex;
    align-items: center;
}
.j-indicator-subcategories-item:hover {
    background-color: #f5f5f5;
}
.j-indicator-subcategories-expand {
    flex: 0 0 15px;
    padding: 0 5px 0 0;
    cursor: pointer;
}
.j-indicator-subcategories-details {
    display: flex;
    align-items: center;
    height: 50px;
}
:not(.j-indicator-subcategories-leaf) > .j-indicator-subcategories-details {
    cursor: pointer;
}
.j-indicator-subcategories-details > div:nth-child(1) {
    padding-right: 25px;
    font-weight: bold;
    font-size: 15px;
}
.j-indicator-subcategories-name { font-weight: bold; font-size: 14px; }
.j-indicator-subcategories-no-name { color: #999; }
.j-indicator-subcategories-controls {
    margin-left: auto;
}
.j-indicator-subcategories-tag-no-header { background-color: #D81B60; }
.j-indicator-subcategories-tag-show-total { background-color: #00acd6; }
.j-indicator-subcategories-tag-quarterly { background-color: #f39c12; }
.j-indicator-subcategories-expand i { transition: transform .30s ease; }
.j-indicator-subcategories-expand i.j-indicator-subcategories-expanded { transform: rotate(90deg); }

.j-indicators-header {}
.j-indicators-header span:nth-child(1) { width: 8%; justify-content: center; text-align: center; }
.j-indicators-header span:nth-child(2) { width: 40%; }
.j-indicators-header span:nth-child(3) { width: 10%; }
.j-indicators-header span:nth-child(4) { width: 27%; }
.j-indicators-header span:nth-child(5) { width: 15%; }
.j-indicators-item div:nth-child(1) { width: 8%; justify-content: center; font-weight: bold; }
.j-indicators-item div:nth-child(2) { width: 40%; }
.j-indicators-item div:nth-child(2) { display: inline-block; }
.j-indicators-item div:nth-child(3) { width: 10%; }
.j-indicators-item div:nth-child(4) { width: 27%; }
.j-indicators-item div:nth-child(5) { width: 15%; justify-content: flex-end; }
.j-indicators-item-tag-special { background-color: rgba(0, 166, 90, 0.65); }

.j-indicators-select {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.j-indicators-select > div:nth-child(1) { width: calc(100% - 220px); }
.j-indicators-select > div:nth-child(2) { width: 200px; padding-bottom: 15px; }

.j-indicators-modal-data-sources {margin-bottom: 15px;}
.j-indicators-modal-data-sources > div:nth-child(1) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.j-indicators-modal-data-sources-select > div:nth-child(1) { width: calc(100% - 95px); }
.j-indicators-modal-data-sources-select > div:nth-child(2) { width: 80px; margin-bottom: 15px; }

.j-indicators-modal-data-sources-list {
    overflow-y: scroll;
    height: 150px;
    border: 1px solid #d2d6de;
}
.j-indicators-modal-data-sources-list > div { outline: 1px solid #d2d6de; }
.j-indicators-modal-data-sources-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d2d6de;
    padding: 6px 11px;
}
.j-indicators-modal-data-sources-list-item:last-child { border: none; }
.j-indicators-modal-data-sources-list-item:hover { background-color: #f5f5f5; }

.j-base-indicators-header {}
.j-base-indicators-header span:nth-child(1) { width: 8%; justify-content: center; text-align: center; }
.j-base-indicators-header span:nth-child(2) { width: 60%; }
.j-base-indicators-header span:nth-child(3) { width: 17%; }
.j-base-indicators-header span:nth-child(5) { width: 15%; }
.j-base-indicators-item div:nth-child(1) { width: 8%; justify-content: center; font-weight: bold; }
.j-base-indicators-item div:nth-child(2) { width: 60%; }
.j-base-indicators-item div:nth-child(2) { display: inline-block; }
.j-base-indicators-item div:nth-child(3) { width: 17%; }
.j-base-indicators-item div:nth-child(4) { width: 15%; justify-content: flex-end; }
.j-base-indicators-item-tag-special { background-color: rgba(0, 166, 90, 0.65); }

@keyframes j-config-item-update {
    0% {background-color: #3c8dbc;}
    100% {background-color: white;}
}

.j-config-item-update {
    animation-name: j-config-item-update;
    animation-duration: 3s;
}

.j-config-modal h3 {
    font-size: 14px;
    margin: 0 0 8px 0;
    padding-bottom: 3px;
    font-weight: bold;
    border-bottom: 1px solid #d2d6de;
}

.j-config-modal .checkbox {
    padding-left: 10px;
}

.j-config-modal-confirm-delete table {
    margin: 20px auto 0;
    border: 1px solid #d2d6de;
    width: 90%;
    font-size: 14px;
}
.j-config-modal-confirm-delete td { padding: 8px; border: 1px solid #d2d6de; }
.j-config-modal-confirm-delete td:nth-child(1) {
    width: 30%;
    font-weight: bold;
    text-align: center;
}

.j-config-modal-confirm-delete td ul {
    padding: 0 0 0 20px;
}

.j-indicator-sum-header {}
.j-indicator-sum-header span:nth-child(1) { width: 35% }

.j-indicator-sum-item {}
.j-indicator-sum-item > div:nth-child(1) {
    flex-direction: column;
    width: 35%;
    padding-right: 20px;
    font-weight: bold;
    align-items: normal;
}
.j-indicator-sum-item > div:nth-child(2) { width: 50%; }
.j-indicator-sum-item > div:nth-child(2) ul { padding: 0 0 0 20px; }
.j-indicator-sum-item > div:nth-child(3) { width: 15%; justify-content: flex-end; }

.j-indicator-sum-modal-sum-of { margin-bottom: 15px; }
.j-indicator-sum-modal-sum-of-select { display: flex; align-items: flex-end; justify-content: space-between; }
.j-indicator-sum-modal-sum-of-select > div:nth-child(1) { width: calc(100% - 95px); }
.j-indicator-sum-modal-sum-of-select > div:nth-child(2) { width: 80px; margin-bottom: 15px; }

.j-indicator-sum-modal-sum-of-list {
    overflow-y: scroll;
    height: 150px;
    border: 1px solid #d2d6de;
}
.j-indicator-sum-modal-sum-of-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d2d6de;
    padding: 6px 11px;
}
.j-indicator-sum-modal-sum-of-list-item:hover { background-color: #f5f5f5; }