.j-indicator-report {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 600px;
    border: 1px solid black;
}

.j-indicator-report-header, .j-indicator-report-group h4 {
    position: sticky;
    top: 0;
    z-index: 5;
    font-size: 15px;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin: 0;
    background-color: white;
}

.j-indicator-report-header { display: flex; padding: 0; font-weight: bold; color: red; border-bottom: 1px solid black;}
.j-indicator-report-header > span { text-align: center; display: block; }
.j-indicator-report-header > span:nth-child(1) {
    width: 35%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-indicator-report-header > span:nth-child(2) {
    width: 10%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-indicator-report-header > span:nth-child(3) {
    width: 10%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-indicator-report-header > span:nth-child(4) {
    width: 10%;
    justify-content: flex-end;
    border-right: 1px solid black;
    padding: 0 6px;
    background-color: #f0f375;
}
.j-indicator-report-header > span:nth-child(5) {
    width: 35%;
    text-align: center;
    justify-content: center;
    font-weight: bold;
}

.j-indicator-report > .j-indicator-report-group > h4 {
    background-color: #1F4E78;
    font-weight: bold;
    color: white;
    z-index: 4;
    top: 24px;
}

.j-indicator-report > .j-indicator-report-group > .j-indicator-report-group > h4 {
    top: 48px;
    background-color: #9BC2E6;
    z-index: 3;
}

.j-indicator-report > .j-indicator-report-group > .j-indicator-report-group > .j-indicator-report-group h4 {
    top: 72px;
    background-color: #9BC2E6;
    z-index: 2;
}

.j-indicator-report .j-indicator-report-group > .j-indicator-report-group > .j-indicator-report-group > .j-indicator-report-group h4 {
    top: 96px;
    background-color: #DDEBF7;
    z-index: 1;
}

.j-indicator-report-group.quarterly h4 {
    background-color: #FFE699 !important;
}

.j-indicator-report-group h4, .j-indicator-report-group-indicator {
    border-bottom: 1px solid black;
}

.j-indicator-report-group.quarterly .j-indicator-report-group-indicator {
    background-color: #FFF2CC;
}

.j-indicator-report-group-indicator {
    display: flex;
    align-items: stretch;
}

.j-indicator-report-group-indicator:focus-within,
.j-indicator-report-group-indicator:hover {
    background-color: #d8dce1 !important;
    font-weight: bold;
}
.j-indicator-report-group-indicator input:focus {
    outline: green solid 3px;
    outline-offset: -2px;
}

.j-indicator-report-group-indicator.special {
    background-color: #C6E0B4;
}

.j-jail-level-report:not(.finalized) .j-indicator-report-group-indicator.incomplete > span:first-child::after {
    content: 'incomplete*';
    padding-left: 0.2em;
    font-size: 80%;
    font-style: italic;
    font-weight: normal !important;
    color: red;
}

.j-indicator-report-group-indicator > span { display: flex; align-items: center; padding: 0 3px; }
.j-indicator-report-group-indicator input {
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    padding: 6px;
    background: none;
}
.j-indicator-report-group-indicator > span:nth-child(1) {
    width: 35%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-indicator-report-group-indicator > span:nth-child(2) {
    width: 10%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-indicator-report-group-indicator:focus-within > span:nth-child(2),
.j-indicator-report-group-indicator:hover > span:nth-child(2) {
    background-color: #d8dce1;
}
.j-indicator-report-group-indicator > span:nth-child(3) {
    width: 10%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-indicator-report-group-indicator:focus-within > span:nth-child(3),
.j-indicator-report-group-indicator:hover > span:nth-child(3) {
    background-color: #fca7fc;
}
.j-indicator-report-group-indicator > span:nth-child(4) {
    width: 10%;
    text-align: right;
    justify-content: flex-end;
    border-right: 1px solid black;
    padding: 0 6px;
    background-color: #f0f375;
}
.j-indicator-report-group-indicator:focus-within > span:nth-child(4),
.j-indicator-report-group-indicator:hover > span:nth-child(4) {
    background-color: #e2e240;
}
.j-indicator-report-group-indicator > span:nth-child(5) {
    width: 35%;
    text-align: center;
    justify-content: center;
    background-color: #D0CECE;
    font-weight: bold;
}
.j-indicator-report-group-indicator:focus-within > span:nth-child(5),
.j-indicator-report-group-indicator:hover > span:nth-child(5) {
    background-color: #bababa;
}

/** base-indicator-report */
.j-base-indicator-report {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 600px;
    border: 1px solid black;
}

.j-base-indicator-report-header,
.j-base-indicator-report-group h4 {
    position: sticky;
    top: 0;
    z-index: 5;
    font-size: 15px;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin: 0;
    background-color: white;
}

.j-base-indicator-report-header {
    display: flex;
    padding: 0;
    font-weight: bold;
    color: red;
    border-bottom: 1px solid black;
}
.j-base-indicator-report-header > span {
    text-align: center;
    display: block;
}
.j-base-indicator-report-header > span:nth-child(1) {
    width: 55%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-base-indicator-report-header > span:nth-child(2) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-base-indicator-report-header > span:nth-child(3) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-base-indicator-report-header > span:nth-child(4) {
    width: 15%;
    justify-content: flex-end;
    padding: 0 6px;
    background-color: #f0f375;
}

.j-base-indicator-report > .j-base-indicator-report-group > h4 {
    background-color: #1F4E78;
    font-weight: bold;
    color: white;
    z-index: 4;
    top: 24px;
}

.j-base-indicator-report > .j-base-indicator-report-group > .j-base-indicator-report-group > h4 {
    top: 48px;
    background-color: #9BC2E6;
    z-index: 3;
}

.j-base-indicator-report > .j-base-indicator-report-group > .j-base-indicator-report-group > .j-base-indicator-report-group > h4 {
    top: 72px;
    background-color: #9BC2E6;
    z-index: 2;
}

.j-base-indicator-report > .j-base-indicator-report-group > .j-base-indicator-report-group > .j-base-indicator-report-group > .j-base-indicator-report-group > h4 {
    top: 96px;
    background-color: #DDEBF7;
    z-index: 1;
}

.j-base-indicator-report-group h4, .j-base-indicator-report-group-indicator {
    border-bottom: 1px solid black;
}

.j-base-indicator-report-group-indicator {
    display: flex;
    align-items: stretch;
}

.j-base-indicator-report-group-indicator:focus-within,
.j-base-indicator-report-group-indicator:hover {
    background-color: #d8dce1 !important;
    font-weight: bold;
}
.j-base-indicator-report-group-indicator input:focus {
    outline: green solid 3px;
    outline-offset: -2px;
}

.j-disease-surveillance-report:not(.finalized) .j-base-indicator-report-group-indicator.incomplete > span:first-child::after {
    content: 'incomplete*';
    padding-left: 0.2em;
    font-size: 80%;
    font-style: italic;
    font-weight: normal !important;
    color: red;
}

.j-base-indicator-report-group-indicator > span { display: flex; align-items: center; padding: 0 3px; }
.j-base-indicator-report-group-indicator input {
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    padding: 6px;
    background: none;
}

.j-base-indicator-report-group-indicator > span:nth-child(1) {
    width: 55%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-base-indicator-report-group-indicator > span:nth-child(2) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-base-indicator-report-group-indicator:focus-within > span:nth-child(2),
.j-base-indicator-report-group-indicator:hover > span:nth-child(2) {
    background-color: #d8dce1;
}
.j-base-indicator-report-group-indicator > span:nth-child(3) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-base-indicator-report-group-indicator:focus-within > span:nth-child(3),
.j-base-indicator-report-group-indicator:hover > span:nth-child(3) {
    background-color: #fca7fc;
}
.j-base-indicator-report-group-indicator > span:nth-child(4) {
    width: 15%;
    text-align: right;
    justify-content: flex-end;
    padding: 0 6px;
    background-color: #f0f375;
}
.j-base-indicator-report-group-indicator:focus-within > span:nth-child(4),
.j-base-indicator-report-group-indicator:hover > span:nth-child(4) {
    background-color: #e2e240;
}