.j-users > button {
    position: absolute;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.j-users-header, .j-roles-header, .j-regions-header, .j-offices-header {
    position: sticky;
    top: 0;
    overflow: auto;
    padding: 0 10px 5px 10px;
    border-bottom: 1px solid #d2d6de;
    background-color: #fff;
    font-weight: bold;
    z-index: 2;
}
.j-users-header span, .j-roles-header span,
.j-regions-header span, .j-offices-header span { display: block; float: left; }
.j-users-header span:nth-child(1) { width: 25%; }
.j-users-header span:nth-child(2) { width: 30%; }
.j-users-header span:nth-child(3) { width: 45%; }

.j-users-item, .j-roles-item, .j-regions-item, .j-offices-item {
    overflow: auto;
    padding: 10px;
    border-bottom: 1px solid #d2d6de;
}

.j-users-item:hover, .j-roles-item:hover, .j-regions-item:hover, .j-offices-item:hover {
    background-color: #f5f5f5;
}

.j-users-item ul, .j-roles-item ul {
    padding: 0 0 0 20px;
    margin-bottom: 0;
    min-height: 1px;
}

.j-users-item > div, .j-roles-item > div, .j-regions-item > div, .j-offices-item > div { float: left; }

.j-users-item > div:nth-child(1) {
    width: 25%;
}

.j-users-item > div:nth-child(1) > span {
    display: block;
    width: 100%;
}

.j-users-item > div:nth-child(1) > span:nth-child(1) { font-weight: bold; }
.j-users-item > div:nth-child(1) > span:nth-child(2),
.j-users-item > div:nth-child(1) > span:nth-child(3)
{ color: #444; font-size: 13px; padding: 1px 0;}
.j-users-item > div:nth-child(1) > span:nth-child(3) { color: #666; font-weight: bold; }

.j-users-item > div:nth-child(2) {
    width: 30%;
}

.j-users-item > div:nth-child(3) {
    width: calc(45% - 100px);
}

.j-users-item > div:nth-child(2) > span,
.j-users-item > div:nth-child(3) > span,
.j-roles-item > div:nth-child(3) > span {
    display: block;
    font-size: 12px;
    padding-left: 20px;
}

.j-users-item > div:nth-child(2) > span > *,
.j-users-item > div:nth-child(3) > span > *,
.j-roles-item > div:nth-child(3) > span > * {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    margin: 0 5px 0 0;
    vertical-align: bottom;
    border: none;
}

.j-users-item > div:nth-child(4) {
    width: 100px;
}

.j-users-item-tag, .j-roles-item-tag, .j-offices-item-tag {
    display: inline-block;
    padding: 1px 8px;
    vertical-align: middle;
    border-radius: 12px;
    font-size: 13px;
    background-color: #00c0ef;
    font-weight: bold;
    color: #fff;
}

.j-users-item-tag-active { background-color: #00c0ef; }
.j-users-item-tag-inactive { background-color: #d73925; }

.j-user-modal h3, .j-role-modal h3 {
    font-size: 14px;
    margin: 0 0 8px 0;
    padding-bottom: 3px;
    font-weight: bold;
    border-bottom: 1px solid #d2d6de;
}

.j-user-modal h3 { text-transform: uppercase; }

.j-user-modal-role-item ul {
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: 13px;
    color: #333;
}

.j-user-modal-role-item > div {
    position: relative;
    padding: 5px 0;
}

.j-user-modal-role-item:hover {
    background-color: #f5f5f5;
}

.j-user-modal-role-item .checkbox {
    margin: 0;
    user-select: none;
}

.j-user-modal-role-item button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.j-user-modal-role-item.added label { color: #008d4c; }
.j-user-modal-role-item.added label::after { content: '*' }

.j-user-modal-role-item.removed label {
    color: #ac2925;
    text-decoration: line-through;
}
.j-user-modal-role-item.removed label::after { content: '*' }

.j-user-modal-no-permission {
    display: block;
    padding: 5px;
    font-style: oblique;
}

.j-user-modal-effective-permissions {
    padding: 5px 0 0 20px;
}

.j-user-modal-effective-permissions li.added { color: #008d4c; }
.j-user-modal-effective-permissions li.added::after { content: '*' }

.j-user-modal-effective-permissions li.removed {
    color: #ac2925;
    text-decoration: line-through;
}
.j-user-modal-effective-permissions li.removed::after { content: '*' }

.j-user-modal-create-success table {
    margin: 20px auto 0;
    border: 1px solid #d2d6de;
    width: 70%;
    font-size: 14px;
    text-align: center;
}

.j-user-modal-create-success td {
    border: 1px solid #d2d6de;
}

.j-user-modal-create-success table td:nth-child(1) {
    width: 33%;
    padding: 15px;
    font-weight: bold;
}

.j-user-modal-create-success table tr:nth-child(2) td:nth-child(2) {
    font-size: 20px;
}

.j-user-modal .btn.center-block {
    padding: 6px 40px;
}

.j-user-modal-password-management { margin-bottom: 20px; }
.j-user-modal-password-management dl { margin-bottom: 0;}
.j-user-modal-password-management dt { margin-bottom: 5px; }
.j-user-modal-password-management dd {
    padding: 6px;
    border: 1px solid #d2d6de;
    background-color: #eee;
}

.j-roles-header span:nth-child(1), .j-roles-item > div:nth-child(1) { width: 25%; }
.j-roles-header span:nth-child(2), .j-roles-item > div:nth-child(2) { width: 20%; }
.j-roles-header span:nth-child(3), .j-roles-item > div:nth-child(3) { width: 40%; }
.j-roles-header span:nth-child(4), .j-roles-item > div:nth-child(4) { width: 15%; }

.j-roles-item > div:nth-child(1) > span { display: block; width: 100%; }
.j-roles-item > div:nth-child(1) > span:nth-child(1) { font-weight: bold; }
.j-roles-item > div:nth-child(1) > span:nth-child(2) { padding-bottom: 8px; }

.j-roles-item-tag { margin-right: 5px; }
.j-roles-item-tag-type-system { background-color: #ca195a; }
.j-roles-item-tag-type-readonly { background-color: rgba(0, 31, 63, 0.35); }
.j-roles-item-tag-type-in-use { background-color: #00a65a; }

.j-role-modal .checkbox {
    padding-left: 10px;
    margin-bottom: 7px;
    user-select: none;
}

.j-role-modal h3 + div {
    padding-bottom: 10px;
}

.j-role-modal-confirm-delete table {
    margin: 20px auto 0;
    border: 1px solid #d2d6de;
    width: 90%;
    font-size: 14px;
}

.j-role-modal-confirm-delete ul { padding: 0 20px; margin: 0; }
.j-role-modal-confirm-delete td { padding: 8px; border: 1px solid #d2d6de; }
.j-role-modal-confirm-delete td:nth-child(1) {
    width: 30%;
    font-weight: bold;
    text-align: center;
}

.j-offices-item > div:nth-child(1), .j-offices-header span:nth-child(1) {width: 10%;}
.j-offices-item > div:nth-child(2) {width: 75%}
.j-offices-item > div:nth-child(3) {width: 15%}

.j-offices-item-tag { margin-left: 5px; }
.j-offices-item-tag-type-in-use { background-color: #00a65a; }