.j-options-report {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 600px;
    border: 1px solid black;
}

.j-jail-level-report:not(.finalized) .j-options-report-item.incomplete > span:first-child::after {
    content: 'incomplete*';
    padding-left: 0.2em;
    font-size: 80%;
    font-style: italic;
    font-weight: normal !important;
    color: red;
}

.j-options-report-header {
    display: flex;
    padding: 0;
    font-weight: bold;
    color: white;
    background-color: #1F4E78;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    text-align: center;
    font-size: 15px;
    line-height: 24px;
}

.j-options-report-header > span { display: block; }
.j-options-report-header > span:nth-child(1) { width: 61%; }
.j-options-report-header > span:nth-child(2) { width: 13%; }
.j-options-report-header > span:nth-child(3) { width: 13%; }
.j-options-report-header > span:nth-child(4) { width: 13%; }

.j-options-report-item {
    display: flex;
    align-items: stretch;
}
.j-options-report-item > span { display: flex; align-items: center; }

.j-options-report-item input {
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    padding: 6px;
    background: none;
}
.j-options-report-item input:focus {
    outline: green solid 3px;
    outline-offset: -2px;
}

.j-options-report-item:focus-within,
.j-options-report-item:hover {
    background-color: #d8dce1;
    font-weight: bold;
}
.j-options-report-item > span { border-bottom: 1px solid black; }
.j-options-report-item > span:not(:last-child) { border-right: 1px solid black; }
.j-options-report-item > span:nth-child(1) { width: 61%; padding: 0 6px; }
.j-options-report-item > span:nth-child(2) { width: 13%; background-color: white; }
.j-options-report-item:focus-within > span:nth-child(2),
.j-options-report-item:hover > span:nth-child(2) {
    background-color: #d8dce1;
}
.j-options-report-item > span:nth-child(3) { width: 13%; background-color: #ffccff; }
.j-options-report-item:focus-within > span:nth-child(3),
.j-options-report-item:hover > span:nth-child(3) {
    background-color: #fca7fc;
}
.j-options-report-item > span:nth-child(4) {
    width: 13%;
    text-align: right;
    justify-content: flex-end;
    padding: 0 6px;
    background-color: #f0f375;
}
.j-options-report-item:focus-within > span:nth-child(4),
.j-options-report-item:hover > span:nth-child(4) {
    background-color: #e2e240;
}

.j-import-modal-partial {

}

.j-import-modal-partial h3 {
    font-size: 14px;
    margin: 0 0 8px 0;
    padding-top: 10px;
    padding-bottom: 3px;
    font-weight: bold;
}

.j-import-modal-partial ul {
    list-style-type: none;
    border: 1px solid #d2d6de;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
}

.j-import-modal-partial li {
    padding: 10px;
    border-bottom: 1px solid #d2d6de;
}

.j-mortality-registry {
    border: 1px solid black;
    height: 600px;
}

.j-mortality-registry-header {
    display: flex;
    padding: 0;
    font-weight: bold;
    color: white;
    background-color: #1F4E78;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    text-align: center;
    font-size: 15px;
    line-height: 24px;
}

.j-mortality-registry-header > span { display: block; }

.j-mortality-registry-item {
    display: flex;
    padding: 0;
    background-color: white;
    border-bottom: 1px solid black;
    line-height: 24px;
}
.j-mortality-registry-item > span {
    display: flex;
    align-items: center;
    padding: 6px;
    overflow-wrap: anywhere;
}
.j-mortality-registry-item:hover { background-color: #d8dce1; }
.j-mortality-registry-item > span:not(:last-child) {border-right: 1px solid black;}
.j-mortality-registry-item > span:nth-child(1),
.j-mortality-registry-header > span:nth-child(1) { width: 19%; }
.j-mortality-registry-item > span:nth-child(2),
.j-mortality-registry-header > span:nth-child(2) { width: 4%; }
.j-mortality-registry-item > span:nth-child(3),
.j-mortality-registry-header > span:nth-child(3) { width: 4%; }
.j-mortality-registry-item > span:nth-child(4),
.j-mortality-registry-header > span:nth-child(4) { width: 14%; }
.j-mortality-registry-item > span:nth-child(5),
.j-mortality-registry-header > span:nth-child(5) { width: 14%; }
.j-mortality-registry-item > span:nth-child(6),
.j-mortality-registry-header > span:nth-child(6) { width: 13%; }
.j-mortality-registry-item > span:nth-child(7),
.j-mortality-registry-header > span:nth-child(7) { width: 11%; }
.j-mortality-registry-item > span:nth-child(8),
.j-mortality-registry-header > span:nth-child(8) { width: 15%; }

.j-mortality-registry-item > span:nth-child(9) { width: 6%; justify-content: center; }

.view-mode .j-mortality-registry-item > span:nth-child(1),
.view-mode .j-mortality-registry-header > span:nth-child(1) { width: 19%; }
.view-mode .j-mortality-registry-item > span:nth-child(2),
.view-mode .j-mortality-registry-header > span:nth-child(2) { width: 4%; }
.view-mode .j-mortality-registry-item > span:nth-child(3),
.view-mode .j-mortality-registry-header > span:nth-child(3) { width: 4%; }
.view-mode .j-mortality-registry-item > span:nth-child(4),
.view-mode .j-mortality-registry-header > span:nth-child(4) { width: 15%; }
.view-mode .j-mortality-registry-item > span:nth-child(5),
.view-mode .j-mortality-registry-header > span:nth-child(5) { width: 15%; }
.view-mode .j-mortality-registry-item > span:nth-child(6),
.view-mode .j-mortality-registry-header > span:nth-child(6) { width: 15%; }
.view-mode .j-mortality-registry-item > span:nth-child(7),
.view-mode .j-mortality-registry-header > span:nth-child(7) { width: 12%; }
.view-mode .j-mortality-registry-item > span:nth-child(8),
.view-mode .j-mortality-registry-header > span:nth-child(8) { width: 16%; }

.j-mortality-registry-modal-confirm-delete {}
.j-mortality-registry-modal-confirm-delete table {
    margin: 20px auto 0;
    border: 1px solid #d2d6de;
    width: 90%;
    font-size: 14px;
}
.j-mortality-registry-modal-confirm-delete td { padding: 8px; border: 1px solid #d2d6de; }
.j-mortality-registry-modal-confirm-delete td:nth-child(1) {
    width: 30%;
    font-weight: bold;
    text-align: center;
}

.j-mortality-registry-modal-confirm-delete td ul {
    padding: 0 0 0 20px;
}