/** Help articles styles */
article.help {
    padding: 0 40px 50px 40px;
}

article.help .help-content-header {
    padding-top: 0;
}

article.help .help-content-header h1 {
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid #d2d6de;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 36px;
}

article.help h2.content-title {
    font-size: 17px;
    line-height: 22px;
    margin-top: 0;
}

article.help .subsection {
    padding-bottom: 10px;
}
article.help h4 { font-weight: bold; }
article.help h4 {
    padding-top: 15px;
}
article.help h5 {
    padding-top: 10px;
    font-size: 15px;
    font-weight: bold;
}

.help-image-container {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.help-image {
    max-width: 100%;
    box-shadow: 0 4px 9px 1px #a1a1a1;
}

.help-table {
    width: 100%;
}
.help-table th, .help-table td {
    padding: 12px 32px 12px 0;
}

.help-table thead tr, .help-table tbody tr:not(:last-child) {
    border-bottom: 1px solid #d2d6de;
}

.help-table .j-config-item-tag {
    white-space: nowrap;
}
.help-table tbody td:nth-child(1) {
    white-space: nowrap;
}
.help-toc {
    position: sticky;
    margin-top: 100px;
    top: 20px;
    border-left: 1px solid #d2d6de;
}
.help-toc-inner {
    margin-bottom: 10px;
}
.help-toc h2, .help-toc-inner h2 {
    font-size: 21px;
    margin-top: 10px;
}

.help-toc > div,
.help-toc-inner > div {
    display: flex;
    align-items: center;
}
.help-toc > div a,
.help-toc-inner > div a {
    margin-left: 15px;
    font-weight: bold;
}