.j-custom-dashboard-indicators h3 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.42857143;
}

.j-custom-dashboard-indicators-list {
    height: 250px;
    width: 100%;
    overflow-y: auto;
}

.j-custom-dashboard-indicators-list-header span:nth-child(1),
.j-custom-dashboard-indicators-list-item div:nth-child(1) {
    width: 40%;
}

.j-custom-dashboard-indicators-list-header span:nth-child(2),
.j-custom-dashboard-indicators-list-item div:nth-child(2) {
    width: 40%;
}

.j-custom-dashboard-indicators-list-item div:nth-child(3) {
    display: block;
    width: 20%;
}

.j-custom-dashboard-components-header {}
.j-custom-dashboard-components-header span:nth-child(1) { width: 8%; justify-content: center; text-align: center }
.j-custom-dashboard-components-header span:nth-child(2) { width: 77%; }
.j-custom-dashboard-components-header span:nth-child(3) { width: 15%; }
.j-custom-dashboard-components-item div:nth-child(1) { width: 8%; justify-content: center; font-weight: bold; }
.j-custom-dashboard-components-item div:nth-child(2) { width: 77%; }
.j-custom-dashboard-components-item div:nth-child(3) { width: 15%; justify-content: flex-end; }