.wrapper, .content-wrapper {
    overflow: initial !important;
}

.j-dash-filter-controls {
    position: sticky;
    padding-top: 1em;
    margin-top: -1em;
    top: 0;
    z-index: 99;
    background-color: #ecf0f5;
    display: flex;
}

.j-dash-filter-controls > div:nth-child(1) {
    width: calc(100% - 180px);
}

.j-dash-filter-controls > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    padding-bottom: 1em;
    padding-left: 20px;
    width: 180px;
}

.j-dash-tabular-group {
    margin-bottom: 1em;
}

.j-dash-tabular-group h4 {
    margin: 0;
    padding: 0 0 0.4em 0;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
}

.j-dash-tabular-group table {
    width: 100%;
    border: 1px solid #d2d6de;
}

.j-dash-tabular-group thead {background-color: #d2d6de; }
.j-dash-tabular-group th, .j-dash-tabular-group td { padding: 0.4em; }
.j-dash-tabular-group tbody tr:hover { background-color: #f5f5f5; }
.j-dash-tabular-group tfoot { font-weight: bold; border-top: 1px solid #d2d6de; }
.j-dash-tabular-group tr > *:not(:first-child) { text-align: right; }

.j-dash-tabular-group.two-column td { border: 1px solid #d2d6de; padding: 0.6em 0.4em; text-align: center; }
.j-dash-tabular-group.two-column td:first-child { font-weight: bold; }

.j-dash-tabular-group.single-row td,
.j-dash-tabular-group.single-row th {
    text-align: center;
}

.padding-bottom {
    padding-bottom: 1em;
}

.j-indicator-group-a-graph {
    height: 21em;
}

.j-indicator-group-b-graph,
.j-indicator-group-c-graph {
    height: 25em;
}

.j-indicator-group-d-graph { height: 29.5em; }

.j-top-ten-group-graph { height: 20em; padding-top: 1em; }

.j-indicator-top-causes-of-death-chart { height: 25em; }
.j-indicator-top-entry-morbidities-chart { height: 21em; }
.j-dash-distribution-screened-chart { height: 25em; }

.j-dash-box-subgroup { margin-bottom: 1.5em; }
.j-dash-box-subgroup h3 {
    margin: 0 0 0.6em 0;
    padding: 0.2em;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    background-color: #8ea9db;
}

.j-dash-mental-disorders th,
.j-dash-mental-disorders td { width: 25%; }

.j-dash-presumptives-table th,
.j-dash-presumptives-table td {
    width: calc(100% / 3);
}

.j-dash-oral-health-chart {
    height: 29em;
}
.j-dash-top-diagnostics-chart {
    height: 29em;
}

.j-dash-covid-chart {
    height: 18em;
}

.j-dash-treatment-pie-chart {
    height: 18em;
}
.j-dash-top-detention-morbidities-chart {
    height: 22em;
}
.j-dash-top-medications-chart {
    height: 25em;
}

.j-dash-who-table th {
    background-color: #06b0f1;
}
.j-dash-who-table, .j-dash-who-table thead tr {
    border: 1px solid #06b0f1;
}