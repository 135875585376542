.content-header h1 {
    display: flex;
    align-items: center;
}

.config-menu {
    border-right: 1px solid #ccc;
}

.config-menu .config-menu-nav {
    padding: 0 5px;
}

.config-menu ul {
    list-style-type: none;
    padding: 0;
}

.config-menu li a {
    padding: 10px;
    display: block;
}

.config-menu li a:hover {
    color: #444;
    background-color: #d8dce1;
}

.config-menu li a.active {
    font-weight: bold;
    color: #333;
}

.config-menu h4 {
    color: #707070;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin: 0 5px 5px 5px;
    padding: 10px 5px;
    border-bottom: 1px solid #a7a7a7;
}

.j-box-scroll {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 500px;
}

.j-highlight {
    background-color: yellow;
}

.row.no-gutter [class*='col-']:first-child {
    padding-right: 5px;
}

.row.no-gutter [class*='col-']:last-child {
    padding-left: 5px;
}

.row.no-gutter [class*='col-']:not(:first-child):not(:last-child) {
    padding-right: 5px;
    padding-left: 5px;
}

.btn.btn-link:active:focus {
    outline: none;
    background: none;
}

.btn.btn-link:active {
    outline: none;
    background: none;
    box-shadow: none;
}

.sidebar {
    position: sticky;
    top: 0;
}

.j-scroll-to-top-container {
    width: 100%;
    position: sticky;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.j-scroll-to-top {
    font-size: 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    color: white;
    background-color: #1F4E78;
}

/** Font enlargement styles */
.main-sidebar, .content-wrapper { font-size: 15px; }
.form-control { font-size: 15px; }
.sidebar-menu { white-space: normal; }

.info-box-text, .progress-description { font-size: 15px; }
.btn { font-size: 15px; }
.btn-group-xs>.btn, .btn-xs { font-size: 13px; }
.dropdown-menu { font-size: 15px; }