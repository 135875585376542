.j-dash-custom-pie {
    height: 300px;
}

.j-dash-custom-horizontal-bar {
    height: 350px;
}

.j-dash-custom-vertical-bar {
    height: 350px;
}