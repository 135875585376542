.consent-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ecf0f5;
}

.widget-consent {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100%;
    max-height: 550px;
    margin: 0;
}

.widget-consent-header {
    flex: none;
    padding: 14px 19px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom: 1px solid #eaeaea;
}

.widget-consent-header h1 {
    font-size: 19px;
    font-weight: 100;
    margin: 0;
    padding: 0;
    color: #444;
}

.widget-consent-body {
    overflow-y: auto;
    overflow-scrolling: touch;
    flex: auto;
    padding: 20px;
    color: #333;
}

.widget-consent-body h2 {
    font-size: 20px;
    font-weight: 100;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 15px;
    text-align: center;
}

.widget-consent-body h2 > span, .widget-consent-warning > span {
    color: #3c8dbc;
}

.widget-consent-body li {
    padding: 3px;
}

.widget-consent-body ul {
    padding-bottom: 20px;
}

.widget-consent-username {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
}

.widget-consent-warning {
    display: inline-block;
    font-size: 16px;
    padding: 20px;
    font-weight: bold;
}

.widget-consent-disclaimer {
    display: inline-block;
    padding: 0 20px;
    color: #777;
}

.widget-consent-footer {
    flex: none;
    padding: 10px 15px;
    border-top: 1px solid #eaeaea;
}

@media (max-width: 520px) {
    .widget-consent-header {
        width: calc(100% - 20px);
    }
}

