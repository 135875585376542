.app-toast-message > span {
    display: block;
    width: 100%;
}

.app-toast-message > span.app-toast-message-title {
    font-weight: bold;
    padding-bottom: 5px;
}

.app-toast-message > span.app-toast-message-subtitle,
.app-toast-message > span.app-toast-message-message {
    font-size: 13px;
}

.app-toast-message-bold {
    font-weight: bold;
}