small .j-stats-item-tag {
    padding: 3px 7px;
}
small .j-stats-item-tag:first-child {
    margin-left: 0;
}

.j-stats-item-tag {
    display: inline-block;
    padding: 2px 8px;
    vertical-align: middle;
    border-radius: 12px;
    font-size: 13px;
    background-color: #00c0ef;
    font-weight: bold;
    color: #fff;
    margin-left: 5px;
}

.j-stats-item-tag-not-submitted { background-color: #f39c12; }
.j-stats-item-tag-unfinalized { background-color: #f39c12; }
.j-stats-item-tag-complete { background-color: #00a65a; }
.j-stats-item-tag-incomplete { background-color: #d33724; }
.j-stats-item-tag-changed { background-color: #d33724; }
.j-stats-item-tag-not-prepared { background-color: #f39c12; }