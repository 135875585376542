.j-disease-surveillance-indicators {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 600px;
    border: 1px solid black;
}

.j-disease-surveillance-indicators-header,
.j-disease-surveillance-indicators-group h4 {
    position: sticky;
    top: 0;
    z-index: 5;
    font-size: 15px;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin: 0;
    background-color: white;
}

.j-disease-surveillance-indicators-header {
    display: flex;
    padding: 0;
    font-weight: bold;
    color: red;
    border-bottom: 1px solid black;
}
.j-disease-surveillance-indicators-header > span {
    text-align: center;
    display: block;
}
.j-disease-surveillance-indicators-header > span:nth-child(1) {
    width: 55%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-disease-surveillance-indicators-header > span:nth-child(2) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-disease-surveillance-indicators-header > span:nth-child(3) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-disease-surveillance-indicators-header > span:nth-child(4) {
    width: 15%;
    justify-content: flex-end;
    padding: 0 6px;
    background-color: #f0f375;
}

.j-disease-surveillance-indicators > .j-disease-surveillance-indicators-group > h4 {
    background-color: #1F4E78;
    font-weight: bold;
    color: white;
    z-index: 4;
    top: 24px;
}

.j-disease-surveillance-indicators > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > h4 {
    top: 48px;
    background-color: #9BC2E6;
    z-index: 3;
}

.j-disease-surveillance-indicators > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > h4 {
    top: 72px;
    background-color: #9BC2E6;
    z-index: 2;
}

.j-disease-surveillance-indicators > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > .j-disease-surveillance-indicators-group > h4 {
    top: 96px;
    background-color: #DDEBF7;
    z-index: 1;
}

.j-disease-surveillance-indicators-group h4, .j-disease-surveillance-indicators-group-indicator {
    border-bottom: 1px solid black;
}

.j-disease-surveillance-indicators-group-indicator {
    display: flex;
    align-items: stretch;
}

.j-disease-surveillance-indicators-group-indicator:focus-within,
.j-disease-surveillance-indicators-group-indicator:hover {
    background-color: #d8dce1 !important;
    font-weight: bold;
}
.j-disease-surveillance-indicators-group-indicator input:focus {
    outline: green solid 3px;
    outline-offset: -2px;
}

.j-disease-surveillance-report:not(.finalized) .j-disease-surveillance-indicators-group-indicator.incomplete > span:first-child::after {
    content: 'incomplete*';
    padding-left: 0.2em;
    font-size: 80%;
    font-style: italic;
    font-weight: normal !important;
    color: red;
}

.j-disease-surveillance-indicators-group-indicator > span { display: flex; align-items: center; padding: 0 3px; }
.j-disease-surveillance-indicators-group-indicator input {
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    padding: 6px;
    background: none;
}

.j-disease-surveillance-indicators-group-indicator > span:nth-child(1) {
    width: 55%;
    border-right: 1px solid black;
    padding: 0 6px;
}
.j-disease-surveillance-indicators-group-indicator > span:nth-child(2) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: white;
}
.j-disease-surveillance-indicators-group-indicator:focus-within > span:nth-child(2),
.j-disease-surveillance-indicators-group-indicator:hover > span:nth-child(2) {
    background-color: #d8dce1;
}
.j-disease-surveillance-indicators-group-indicator > span:nth-child(3) {
    width: 15%;
    padding: 0;
    border-right: 1px solid black;
    background-color: #ffccff;
}
.j-disease-surveillance-indicators-group-indicator:focus-within > span:nth-child(3),
.j-disease-surveillance-indicators-group-indicator:hover > span:nth-child(3) {
    background-color: #fca7fc;
}
.j-disease-surveillance-indicators-group-indicator > span:nth-child(4) {
    width: 15%;
    text-align: right;
    justify-content: flex-end;
    padding: 0 6px;
    background-color: #f0f375;
}
.j-disease-surveillance-indicators-group-indicator:focus-within > span:nth-child(4),
.j-disease-surveillance-indicators-group-indicator:hover > span:nth-child(4) {
    background-color: #e2e240;
}