.simple-info-box {
    background-color: #d2d6de;
    color: black !important;
}

.simple-info-box .info-box-text,
.simple-info-box .info-box-number,
.simple-info-box .progress-description {
    text-align: center;
}

.simple-info-box.bg-blue {
    background-color: #214d77 !important;
    color: white !important;
}
.simple-info-box.bg-yellow { background-color: #f7e197 !important; }
.simple-info-box.bg-green { background-color: #bbe0d3 !important; }
.simple-info-box.bg-maroon {
    background-color: #843b49 !important;
    color: white !important;
}
.simple-info-box .progress .progress-bar {
    background-color: black;
}